/* eslint-disable react/prop-types */
import React, { memo } from 'react';
import { IUpdateLogs } from '../../rmsReduxStore/ruleEngineRedux/ruleEngineType';
import { formatDateWithFullTime, getUserFromId } from '../../globalUtils/globalHooks';
import './ExecutionLogsSidePopover.css'
import CustomAvatar from '../../globalUtils/CustomAvatar/CustomAvatar';

interface ExecutionSummarySidePopoverProps {
  ruleId: string;
  updateLogs: IUpdateLogs[]|null
}

const ExecutionLogsSidePopover = memo(function ExecutionSummarySidePopover({ updateLogs }: ExecutionSummarySidePopoverProps) {
  return (
    // <Box p={2} style={{ height: 'calc(100dvh - 150px)', overflowY: 'auto',marginLeft:'-15px' }}>
    //   <List>
    //     {logs.map((log) => (
    //       <React.Fragment key={log.id}>
    //         <ListItem alignItems='flex-start' disableGutters>
    //           <Grid container alignItems='center'>
    //             {/* Avatar */}
    //             <Grid item xs='auto' sx={{marginRight:'5px'}}>
    //               <Avatar sx={{ bgcolor: log.avatarColor, fontWeight: 'bold', width: 25, height: 25, fontSize: '0.875rem'}}>
    //                 {log.avatar}
    //               </Avatar>
    //             </Grid>
    //             {/* Text Content */}
    //             <Grid item xs>
    //               <Typography variant='body2' component='span' sx={{fontSize:'12px'}}>
    //                 <strong>{log.user}</strong> {log.action} <strong>{log.details}</strong>
    //               </Typography>
    //             </Grid>
    //             {/* Timestamp */}
    //             <Grid item xs='auto'>
    //               <Typography variant='caption' color='textSecondary' style={{ whiteSpace: 'nowrap' }}>
    //                 {log.timestamp}
    //               </Typography>
    //             </Grid>
    //           </Grid>
    //         </ListItem>
    //         <Divider component='li' />
    //       </React.Fragment>
    //     ))}
    //   </List>
    // </Box>
    // eslint-disable-next-line react/prop-types
    <>
      <div className='update__log__container'>
      { updateLogs?.length ===0 ? <div className='update__log__no__data'>No Data</div> :
         updateLogs?.map((item,index) => {
          return (
              <>
                <div key = {index} className='update__log__individual__row'>
                  <CustomAvatar containerClassName='crm__activity__log__row_user' diameter={20} titleText={getUserFromId(item?.userId) ?? 'System Admin'} hideText={true} />
                  <div className='d-flex gap-4' dangerouslySetInnerHTML={{__html: item?.logEntry ?? ''}} style={{width:'100%',flexWrap: 'wrap'}}></div>
                  <div style={{display: 'flex',justifyContent: 'flex-end',width: '32%'}}>
                    {formatDateWithFullTime(item?.createdAt)}
                  </div>
               </div>
               {/* {index !== updateLogs?.length-1 && <hr className='custom-hr'/>} */}
              </>
          )  
        })
      }
      </div>
    </>

  );
});

export default ExecutionLogsSidePopover;