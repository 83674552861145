import { type GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid-pro';
import { cloneDeep} from 'lodash';
import React, { ReactNode, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {

  CustomTooltipWithLabel,
  downloadFile,
  renderHeaderActionButtons,
  SidePopover,
  TableDashboard,
  useAppDispatch,
  useAppSelector,
} from '../../globalUtils/globalExports';


import {
  getChargerReducer,
  getCurrentFiltersValues,
  getGlobalReducer,
  setTableColumnPrefrences,
  chargerTypes,
  fetchSpuriousChargersData,
  setSpuriousChargersListTableProps,
  exportToCsvForSpuriousChargersList
} from '../../rmsReduxStore/reduxExports';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import './chargers.css';
import { Id, toast } from 'react-toastify';
import { ActionIcons} from '../../globalUtils/globalIcons';
import { NoDataComponentSpuriousChargers } from '../../globalUtils/TableDashboard/TableComponents';
import { formatRelativeTimeForTable } from '../../globalUtils/globalHooks';
import access from '../../auth/service/AccessControl';
import { EditSpuriousCharger } from '../componentExports';





const SpuriousChargersDashboard = memo(function SpuriousChargersDashboard() {
  const dispatch = useAppDispatch();
  // const navigate = useNavigate();
  const layouts = ['list', 'map'];
  const { spuriousChargers, chargerParametersTableLoader, spuriousChargersListTableProps,spuriousChargersCount } = useAppSelector(getChargerReducer);
  const intialChargerData : SpuriousChargers={
    id: '',
    chargerSerialNumber: '',
    macAddress: '',
    serverUrl: '',
    status: '',
    lastPingTime: '',
    createdAt: '',
    updatedAt: ''
  }
  const [exportAnchorEl, setExportAnchorEl] = useState(null);
  const [exportingFile, setExportingFile] = useState(false);
  const spuriousChargersListTablePropsRef = useRef(spuriousChargersListTableProps);
  const parametersRef = useRef(spuriousChargers);
  const {screenTableColumnPrefrences} = useAppSelector(getGlobalReducer)
  const [isSidePopOverOpen,setIsSidePopOverOpen]= useState(false);
  const [singleChargerData,setChargerSingledata]= useState(intialChargerData);

  

  useEffect(() => {
    spuriousChargersListTablePropsRef.current = spuriousChargersListTableProps;
  }, [spuriousChargersListTableProps]);

  useEffect(() => {
    parametersRef.current = spuriousChargers;
  }, [spuriousChargers]);

  const onChangeOfSearchText = useCallback(async (searchTerm) => {
    if (spuriousChargersListTablePropsRef?.current) {
      await layoutCallback(1, spuriousChargersListTablePropsRef?.current?.pageSize, 'list', spuriousChargersListTablePropsRef?.current?.sortBy, spuriousChargersListTablePropsRef?.current?.order, null, searchTerm)
    }
  }, [spuriousChargers, spuriousChargersListTablePropsRef?.current, parametersRef?.current]);

  const searchBoxConfiguration = useMemo(() => {
    return {
      searchFieldId: 'manage-charger-parameters-search-box',
      searchFieldName: 'manage-charger-parameters-search-box',
      handleSearch: onChangeOfSearchText,
    };
  }, [onChangeOfSearchText]);

  // TODO: Status Icon display
  const getStatusIconClass = {
    'InProgress': 'status__in-progress__parameter__icon',
    'Scheduled': 'status__scheduled__parameter__icon',
    'Completed': 'status__completed__parameter__icon',
    'Enabled':'status__completed__parameter__icon',
  }


  const headerActionBtns = renderHeaderActionButtons({
    actionBtns:
      [
        {
          id: 'export_csv_btn',
          isAccessible: access?.updateSetting?.export(),
          headerActionBtnClick: (event): void => {
            setExportAnchorEl(event?.currentTarget);
          }
        }
      ]
  })



  // to handle edt-icon
  const onEditIconClick = (rowData) => {
    setIsSidePopOverOpen(true);
    setChargerSingledata(rowData);
  };

  const actionIconsList = useMemo(() => {
    return [{ icon: 'pencilIcon',isAccessible: access?.updateSetting?.edit(), action:(_,rowData)=> onEditIconClick(rowData)}];
  }, [onEditIconClick]);


  // TODO: Table Layout Callbacks
  const mobileViewConfiguration = useMemo(() => {
    return {
      headerDataConfig: {
        headerLeftDataConfig: ['chargerSerialNumber','macAddress','serverUrl'],
        headerRightDataConfig: {
          actionIconsComponent: true,
          statusIconComponent: true,
        },
      },
      statusIconKey: 'status',
      statusIconMapper: getStatusIconClass,
      contentDataConfig: [
        [
          {
            key: 'lastPingTime',
            iconClassName: '',
          },
          {
            key: 'createdAt',
            iconClassName: 'parameters_createdOn_icon',
          },
        ],
      ],
    };
  }, []);

  const getTableRows = useCallback((): SpuriousChargers[] => {
    return spuriousChargers !== null && spuriousChargers?.length > 0 ? spuriousChargers : [];
  }, [spuriousChargers]);

  const spuriousChargerColumns = useMemo(() => {

    return [

      {
        field: 'chargerSerialNumber',
        headerName: 'Charger Serial Number',
        maxWidth: 500,
        sortable: true,
        minWidth: 200,
        hideable:false,
      },
      {
        field: 'macAddress',
        headerName: 'Mac Address',
        flex: .6,
        sortable: true,
        minWidth: 80,
      },
      {
        field: 'chargerOCPPID',
        headerName: 'Charger OCPP ID',
        flex: .6,
        sortable: true,
        minWidth: 170,
        renderCell: (
          params: GridRenderCellParams<GridValidRowModel>
        ): ReactNode => {
          const rowData = params?.row;
          return (
            <>
               <CustomTooltipWithLabel label={rowData.chargerOCPPID} labelId={rowData.chargerOCPPID} />
            </>
          )
        },
      },
      {
        field: 'serverUrl',
        headerName: 'Server URL',
        flex: .6,
        sortable: true,
        minWidth: 170,
        renderCell: (
          params: GridRenderCellParams<GridValidRowModel>
        ): ReactNode => {
          const rowData = params?.row;
          return (
            <>
               <CustomTooltipWithLabel label={rowData.serverUrl} labelId={rowData.serverUrl} />
            </>
          )
        },
      },
      {
        field: 'lastPingTime',
        flex: .6,
        headerName: 'Last Ping Time',
        minWidth: 150,
        renderCell: (
          params: GridRenderCellParams<GridValidRowModel>
        ): ReactNode => {
          const rowData = params?.row;
          const date = new Date(rowData?.lastPingTime);
          return formatRelativeTimeForTable(date);
        },
      },
      {
        field: 'chargerStatus',
        headerName: 'Status',
        flex: .6,
        sortable: true,
        minWidth: 120,
      },
      {
        field: 'chargingStatus',
        headerName: 'Charging Status',
        flex: .6,
        sortable: true,
        minWidth: 120,
      },
      {
        field: 'createdAt',
        flex: .6,
        headerName: 'Created At',
        minWidth: 150,
        renderCell: (
          params: GridRenderCellParams<GridValidRowModel>
        ): ReactNode => {
          const rowData = params?.row;
          const date = new Date(rowData?.createdAt);
          return formatRelativeTimeForTable(date);
        },
      },
      {
        field: 'View',
        headerName: 'Actions',
        flex: 1,
        sortable: false,
        minWidth: 90,
        maxWidth: 100,
        headerAlign: 'center',
        renderCell: (params: GridRenderCellParams<GridValidRowModel, Date>): JSX.Element => {
          const rowData = params?.row;
           if (rowData?.chargerStatus !== 'Offline' && rowData?.chargingStatus === 'Idle' ) {
            return (
              <div className="charging__station__action__icons__wrap">
                <ActionIcons actionIconsList={actionIconsList} data={rowData} />
              </div>
            );
           }
           else return (<div></div>)
        },
      },
    ];
  }, []);




  const layoutCallback = useCallback(async (pageNumber: number, pageSize: number, view: string, sortField: string, sortOrder: string, filterData, searchTerm) => {
    const tableProps: IChargerListTableProps = cloneDeep(spuriousChargersListTablePropsRef?.current)

    await dispatch(fetchSpuriousChargersData({
      sortBy: sortField || spuriousChargersListTablePropsRef?.current?.sortBy,
      order: sortOrder || spuriousChargersListTablePropsRef?.current?.order,
      pageSize: pageSize || 15,
      pageNumber,
      filters: filterData ? filterData : getCurrentFiltersValues(''),
      searchTerm: searchTerm != null && searchTerm != undefined ? searchTerm : spuriousChargersListTablePropsRef?.current?.searchTerm
    }));

    if (tableProps) {
      tableProps.view = view ?? 'list'
      tableProps.sortBy = sortField || spuriousChargersListTablePropsRef?.current?.sortBy;
      tableProps.order = sortOrder || spuriousChargersListTablePropsRef?.current?.order;
      tableProps.pageNumber = pageNumber;
      tableProps.pageSize = pageSize || 15;
      if (searchTerm != null && searchTerm != undefined) {
        tableProps.searchTerm = searchTerm;
      }
    }
    await dispatch(setSpuriousChargersListTableProps(tableProps))
  }, [spuriousChargersListTablePropsRef?.current, dispatch]);

    // Handler For Column Prefrence Chnages
    const handleColumnPrefrenceChange = useCallback(async (operation,data) => {
      await dispatch(setTableColumnPrefrences('spuriousChargers',operation,data))
   },[])

  // Redux state Clean up
  const spuriousChargersListCleanUpState = useCallback(() => {
    const action = {
      type: chargerTypes.CLEAR_SPURIOUS_CHARGERS_LIST_DATA,
    }
    dispatch(action);
  }, [])
  useEffect(() => {
    window.addEventListener('beforeunload', spuriousChargersListCleanUpState);
    return (): void => {
      spuriousChargersListCleanUpState()
      window.removeEventListener('beforeunload', spuriousChargersListCleanUpState);
    }
  }, [])

  // TODO: Export Handlers
  const handleClose = (): void => {
    setExportAnchorEl(null);
  };


  const fileDownloadSuccessToast = useCallback((): Id => {
    setExportingFile(false);
    return toast.success('File downloaded.');
  }, []);

  const fileDownloadFailureToast = useCallback((): Id => {
    setExportingFile(false);
    return toast.warn('Something went wrong. Please export again.');
  }, []);


  const onExportBtnClick = useCallback(async (fileType: 'csv' | 'xlsx'): Promise<void> => {
    if (exportingFile) {
      // If already exporting, prevent another export
      return;
    }
    try {
      const response = await dispatch(exportToCsvForSpuriousChargersList({
        fileType: fileType,
        filters: getCurrentFiltersValues('')
      }));
      if (response?.status === 202 || response?.status === 200) {
        downloadFile({ url: response?.data?.url });
        fileDownloadSuccessToast()
      } else {
        fileDownloadFailureToast();
        setExportAnchorEl(null);
      }
    } finally {
      // Ensure that exporting status is updated even if there's an error
      setExportingFile(false);
      setExportAnchorEl(null);

    }
  }, [exportingFile, fileDownloadSuccessToast, fileDownloadFailureToast])
  return (
    <>
      <TableDashboard
        tableId="manage__spurious_chargers__table"
        tableClassName="manage__spurious_chargers__table"
        header={'Spurious Chargers'}
        searchBoxIncluded={true}
        searchBoxConfiguration={searchBoxConfiguration}
        gridColumns={spuriousChargerColumns}
        tableRows={getTableRows()}
        mobileViewConfiguration={mobileViewConfiguration}
        layouts={layouts}
        layoutView={'list'}
        headerActionBtns={headerActionBtns}
        listLayoutCallBack={layoutCallback}
        totalCount={spuriousChargersCount ??0}
        showSkeleton={!spuriousChargers}
        totalCountText={'spurious chargers'}
        showLoader={chargerParametersTableLoader}
        customNoDataComponent={NoDataComponentSpuriousChargers}
        pageNumber={spuriousChargersListTableProps?.pageNumber}
        tablePageSize={spuriousChargersListTableProps?.pageSize}
        sortField={spuriousChargersListTableProps?.sortBy}
        sortOrder={spuriousChargersListTableProps?.order}
        referenceDataCallbacksFilters={['']}
        columnPrefrences={screenTableColumnPrefrences?.find((item) => item.screen === 'spuriousChargers')}
        handleColumnPrefrenceChange={handleColumnPrefrenceChange}
      />

      <Menu
        id="export-option-dropdown"
        anchorEl={exportAnchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        className='export__option__dropdown'
        sx={{ top: '30px' }}
        open={Boolean(exportAnchorEl)}
        onClose={handleClose}
      >
        <MenuItem>
          <div className='export__btn-option' onClick={(e) => {
            e?.stopPropagation();
            onExportBtnClick('csv')
          }}>
            To CSV
          </div>
        </MenuItem>
        <MenuItem>

          <div className='export__btn-option' onClick={(e) => {
            e?.stopPropagation();
            onExportBtnClick('xlsx')
          }}>
            To Excel
          </div>
        </MenuItem>
      </Menu>
      
  
          <SidePopover 
          id={'spurious_charger_update'}
          headerText={'Update Charger'}
          open={isSidePopOverOpen}
          handleClose={()=>{
                setIsSidePopOverOpen(false);
          }}
           // eslint-disable-next-line react/no-children-prop
          children={<EditSpuriousCharger chargerData={singleChargerData} handleCloseSidePopover={()=>{
           setIsSidePopOverOpen(false);
          }}/>}/>

      
    </>
  );
})

export default SpuriousChargersDashboard;
