/* eslint-disable @typescript-eslint/ban-types */
import React, {  useState } from 'react';
import Box from '@mui/material/Box'; // MUI box for styling
import Typography from '@mui/material/Typography'; // MUI for text
// import {  IconButton, } from '@mui/material';
import { Handle, Position } from '@xyflow/react';
import { getReferenceDataReducer, fetchAlarmRulesExecutionPlan } from '../../../rmsReduxStore/reduxExports';
import { SelectDropDown, useAppDispatch, useAppSelector, useEffectOnce } from '../../../globalUtils/globalExports';
import ExecutionPlan from './ExecutionPlan';
// import { cloneDeep } from 'lodash';
// import CloseIcon from '@mui/icons-material/Close';

// import { TriggerPointSidePopover } from '../../componentExports';
import './ParameterNode.css'
import { cloneDeep } from 'lodash';
import { IconWithTooltip } from '../../../globalUtils/globalIcons';


interface ParameterNodeProps {
  data: {
    type: 'placeholder' | 'parameter' | 'other';
    label?: string;
    executionPlan: ExecutionPlan;
    handleUpdateExecutionPlan: Function;
    delete:Function
  };
}

const AlarmNode: React.FC<ParameterNodeProps> = (props) => {
  const dispatch = useAppDispatch();
  
  const { alarmNameReferenceDataExecutionPlan } = useAppSelector(getReferenceDataReducer)
  const [selectedTriggerValue, setSelectedTriggerValue] = useState<string>('');
  // const [isTriggerParameterPopoverOpen, setIsTriggerParameterPopoverOpen] = useState(false);

  // const handleEdit =() => {
  //   // setIsTriggerParameterPopoverOpen(true);
  // }
  // const handleCloseTriggerParameterPopover = () =>{
  //   // setIsTriggerParameterPopoverOpen(false);
  // }
  useEffectOnce(()=>{
    console.log(props?.data,'props');
    if(props?.data?.executionPlan){
      const data = props?.data?.executionPlan?.trigger?.name;

      console.log(data,'alarnNodename');
      setSelectedTriggerValue(data??'');
    }
  })

  useEffectOnce(() => {
    dispatch(fetchAlarmRulesExecutionPlan());
  })
    return (
      <>
    <Box
      sx={{
        width: 300,
        border: '1px solid #00BFBF', // border color
        borderRadius: '12px',
        padding: 2,
        backgroundColor: '#fff',
        borderLeft:'7px solid #00BFBF '
      }}>
          <Handle type="source" className='transparent-handle' position={Position.Bottom}
            // style={{ left: '20%', transform: 'translateX(-50%)' }}
          />
    <Handle type="source"  className='transparent-handle' position={Position.Left} id='left'  />
      {/* Title with icon */}
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
      <IconWithTooltip iconClassName={'define_execution__bolt_black__icon'} />
        <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>Trigger</Typography>
      </Box>

      {/* 'in Parameters' section */}
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1,gap:'4px' }}>
        <Typography sx={{ fontSize: 12, mr: 1 }}>in</Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            border: '1px solid #ccc',
            borderRadius: '4px',
            padding: '4px 8px',
            backgroundColor: '#f9f9f9',
           flex: 1,
            gap:'8px'
          }}
        >
          <IconWithTooltip iconClassName={'trigger-alarms-sidebar'} />
          <Typography sx={{ fontSize: 14,marginBottom:'1px' }}>Alarms</Typography>
        </Box>
        {/* <IconButton
        size="small"
        onClick={()=>{}}
        sx={{
          position: 'absolute',
          right: '8px',
          top: '8px',
        }}
      >
        <CloseIcon
      onClick={()=> props?.data?.delete()}
          
          fontSize="small" sx={{ color: '#666' }} />
      </IconButton> */}

      </Box>
    
        
      <div style={{display:'flex',alignItems:'center',gap:'4px'}}>
        <Typography sx={{ fontWeight: '500', fontSize: '12px',marginLeft:'-10px' }}>when</Typography>    
      <div style={{width:'-webkit-fill-available'}}>      
              <SelectDropDown
        isDisabled={props?.data?.executionPlan?.trigger?.alarmTypeId ? true : false}
        dropDownClassName={'nodrag nopan'}
        selectDropDownId={'rule__engine__paramter'}
        selectValue={{id: selectedTriggerValue, label : selectedTriggerValue}} 
        renderToBody={true}
        handleSelectOptionChange={(val) => {
          setSelectedTriggerValue(val?.label);
          const executionPlanData = cloneDeep(props?.data?.executionPlan);
          executionPlanData.trigger.type = 'Alarm';
          executionPlanData.trigger.alarmTypeId = val?.id;
          executionPlanData.trigger.parameterCondition = 'is'
          executionPlanData.trigger.parameterValue[0] = 'Active'
          props?.data?.handleUpdateExecutionPlan(executionPlanData);          
        }} 
        selectOptions={
          alarmNameReferenceDataExecutionPlan ?? []
        }   
        isMultiSelect={false}     
      />
        </div>
      </div>
    </Box>
      {/* {isTriggerParameterPopoverOpen && <SidePopover
          id='rule__engine__execution__plan__trigger__parameter__side__popover'
          open={isTriggerParameterPopoverOpen}
          headerText={'When'}
          // headerSecondaryText={'Condition'}
          containerClassName='configure__notification__detail__popover'
          handleClose={()=>{}}
          popperClassName='trigger__point__side__popover'
          headerClassName={'rule__engine__header__config'}
          horizontalLinePopover={true}
          isRuleEnginePopOver={true}
          ruleEngineSidePopoverOnclose={handleCloseTriggerParameterPopover}
          // eslint-disable-next-line react/no-children-prop
          children={
                <TriggerPointSidePopover 
                    handleClose={handleCloseTriggerParameterPopover} 
                    executionPlan={props?.data?.executionPlan}
                    handleUpdateExecutionPlan={props?.data?.handleUpdateExecutionPlan}
                    parameterOrAlarm={'parameter'}
                />
          } 
          />
        } */}
      </>

      
      
    );
};

export default AlarmNode;
