
import React, { FC, useCallback, useMemo, useState } from 'react';
import {
    CustomButtonGroup,
    useAppDispatch,

    CustomInput,
   
    CustomDialog,


} from '../../../globalUtils/globalExports';

import {
    updateSpuriousCharger,

} from '../../../rmsReduxStore/reduxExports';
import {  toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import './SpuriousCharger.css';
import 'react-datepicker/dist/react-datepicker.css';


interface ChargerProps{
    chargerData:SpuriousChargers
    // eslint-disable-next-line @typescript-eslint/ban-types
    handleCloseSidePopover:Function;
}
const EditSpuriousCharger: FC<ChargerProps> = (props) => {

    const {chargerData,handleCloseSidePopover} = props;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    // const location = useLocation();
    const [chargerSerialNumber, setChargerSerialNumber] = useState(chargerData.chargerSerialNumber);
    const [isDialogBoxOpen,setIsDialogBoxOpen]=useState(false);

    const disableAddButton = useCallback(() => {
        return chargerData.chargerSerialNumber === chargerSerialNumber;
    }, [chargerSerialNumber])
    
    const handleChargerSerialUpdate = async () =>{
        const updateBody = {
            chargerId: chargerData?.id,
            chargerSerialNumber:chargerSerialNumber,
            key: 'ss$os$updateChargerSerialNumber',
            values: [chargerSerialNumber]
        }
        const response: AxiosResponse = await dispatch(updateSpuriousCharger(updateBody));
        if (response?.status === 200 || response?.status === 202) {
            if(response?.data?.updated){
                toast.success('Charger serial number updated.');
                setTimeout(() => {
                    navigate('/spurious-chargers');
                }, 2000)
            }
            else{
                toast.info(response?.data?.message);
            }
        }
        else {
            toast.warn('Something went wrong.');
            setTimeout(() => {
                setChargerSerialNumber('')
            }, 3000)
            setTimeout(() => {
                navigate('/spurious-chargers');
            }, 2000)
        }
    }
    const btnsList = useMemo(() => {
        return [
            {
                buttonText: 'Update',
                buttonId: 'add',
                btnClassName: disableAddButton() ? 'primary__btn disabled' : 'primary__btn',
                handleClick: () => {
                  setIsDialogBoxOpen(true);
                },
                isDisabled: disableAddButton(),
                buttonVariant: 'filled',
            },
            {
                buttonText: 'Cancel',
                buttonId: 'cancel',
                btnClassName:'secondary__btn',
                handleClick: () => {
                    handleCloseSidePopover();
                },
                isDisabled:false,
                buttonVariant: 'outlined',
            },

        ];
    }, [disableAddButton]);
    function handleChange(value: string) {
       setChargerSerialNumber(value);
    }

    return (
        <>
            <div className='spurious_charger_form_wrap'>
                <div className='edit-spurious-charger-content-wrap'>
                    <div className="spurious-chargers-charger-serial-no-container">
                        <div className="spurious-chargers-charger-serial-no-heading">
                            Charger Serial Number
                        </div>
                        <div className="spurious-chargers-charger-serial-no">
                            <CustomInput
                                inputPlaceHolder="Charger Serial Number"
                                handleInputChange={(e) => { handleChange(e.target.value) }}
                                inputValue={chargerSerialNumber}
                                inputFieldId="charger-serial-number"
                                isRequired={true}
                            />
                        </div>
                    </div>
                    <div className='update_spurious_charger__btns'>
                        <CustomButtonGroup buttonsList={btnsList} buttonGroupClassName='button__group__footer' />
                    </div>
                </div>
            </div>
            <CustomDialog
                dialogConfig={{
                    dialogDescription: 'Charger serial number would be updated, and charger will be rebooted to apply the updated serial number',
                    dialogOkTitle: 'Confirm',
                    dialogCancelTitle:'Cancel',
                }}
                show={isDialogBoxOpen}
                handleSubmit={() => {setIsDialogBoxOpen(false); handleChargerSerialUpdate(); }}
                handleClose={()=>{setIsDialogBoxOpen(false)}}
            />



        </>
    );
};
export default EditSpuriousCharger;

