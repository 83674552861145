/* eslint-disable @typescript-eslint/no-unused-vars */
import { type FC } from 'react'
import React from 'react';
import './CustomAvatar.css'
import { width } from '@mui/system';
import { CustomTooltipWithLabel } from '../globalExports';
import { IconWithTooltip } from '../globalIcons';
import { getFirstInitialOfName,getInitialsOfName } from '../globalHooks';

export interface CustomAvatarProps {
  titleText?: string;
  containerClassName?: string;
  iconClassName?: string;
  diameter?: number;
  imageUrl?: string;
  hideText?: boolean;
  showWhiteBorder?: boolean;
  showOnlyFirstLetter?: boolean;
}

export const CustomAvatar: FC<CustomAvatarProps> = (props) => {
  const { containerClassName, titleText, iconClassName, diameter, imageUrl, hideText, showWhiteBorder, showOnlyFirstLetter } = props;

  const validColors = [
    '#5687D3',
    '#E26B51',
    '#53BB71',
    '#07BFBF',
    '#EBA901',
    '#C879DD',
    '#C7482C',
    '#7E54D6',
    '#C48200'
  ];

  const color = generateColor();
  function generateColor() {
    const hash = titleText?.split('').reduce((acc, char) => char.charCodeAt(0) + acc, 0);
    const colorHash = (hash ?? 0) % validColors.length;
    return validColors[colorHash];
  }

  return (
    <>
      <div className={'d-flex rms__avatar__wrap ' + containerClassName}>
        {imageUrl && <img alt='--' src={imageUrl} style={{ width: diameter ?? 30, borderRadius: '50%', height: diameter ?? 30 }} />}
        {iconClassName && <div style={{ width: diameter ?? 30, height: diameter ?? 30 }}> <IconWithTooltip containerClassName={showWhiteBorder ? 'avatar__white__border' : ''} iconClassName={iconClassName}></IconWithTooltip> </div>}
        {!(imageUrl || iconClassName) && titleText &&
          <div className={'d-flex align-center justify-center circle '} style={{ backgroundColor: color, width: diameter ?? 30, height: diameter ?? 30 }}>
            <div className={((diameter ?? 30) > 30) ? 'rms__avatar__fontStyle__large' : 'rms__avatar__fontStyle__small'}> {showOnlyFirstLetter ? getFirstInitialOfName(titleText) : getInitialsOfName(titleText)} </div>
          </div>
        }
        {!hideText && titleText &&
          <CustomTooltipWithLabel containerClassName='rms__avatar__wrap_text w-90' label={titleText} labelId='titleText' />
        }
      </div>
    </>
  )
}

export default CustomAvatar;