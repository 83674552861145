import { referenceDataTypes } from '../reduxExports';

const referenceDataInitialState: ReferenceDataState = {
  regionReferenceData: null,
  softwareVersionReferenceData: null,
  chargerStatusReferenceData: null,
  customerReferenceData: null,
  chargerTypeReferenceData: null,
  alarmSeverityReferenceData: null,
  chargerModelReferenceData: null,
  chargingStationReferenceData: null,
  chargerLocationReferenceData: null,
  chargerHistoryTypeReferenceData: null,
  chargerHistoryIntervalReferenceData: null,
  alarmTypeReferenceData: null,
  chargerVisibleIdReferenceData: null,
  userRoleTypeReferenceData: null,
  parametersReferenceData: null,
  userReferenceData: null,
  chargerVisibilityReferenceData: null,
  orgReferenceData: null,
  orgTypeReferenceData: null,
  rolePermissionReferenceData: null,
  exceptionLogsSeverityReferenceData: null,
  allowedCommandsForRoleReferenceData: null,
  chargerProductTypeReferenceData: null,
  alarmNameReferenceData: null,
  typeOfNotificationReferenceData: null,
  ruleIdConfigurNotificationHistoryReferenceData: null,
  ruleAlarmTypes: null,

  ruleEngineTriggerCategoryReferenceData: null,
  ruleEngineTriggerLevelReferenceData: null,
  ruleEngineActionControlReferenceData: null,
  ruleEngineScheduleReferenceData: null,
  ruleEngineTriggerPointParameterReferenceData: null,
  ruleEngineStatusReferenceData: null,
  ruleEngineParamConditionReferenceData: null,
  ruleEngineParamOperatorReferenceData: null,
  ruleEngineActionControlSendNotificationReferenceData: null,
  ruleEngineRaiseATicketCategoryReferenceData: null,
  ruleEngineRaiseATicketUrgencyReferenceData: null,

  ruleEngineRuleNameReferenceData: null,
  ruleEngineSetParameterParam: null,
  ruleEngineRemoteCommands: null,
  alarmRuleSeverityReferenceData: null,
  chargerActiveCertificateReferenceData: null,
  ruleEngineRaiseATicketCallTypeReferenceData: null,
  alarmNameReferenceDataExecutionPlan: null,

  resetCounterRemoteCommandReferenceData:null,
};

const referenceDataReducer = (state: ReferenceDataState = referenceDataInitialState, action: ReferenceDataAction): ReferenceDataState => {
  switch (action.type) {
    case referenceDataTypes.FETCH_CHARGER_STATUS_REFERENCE_DATA:
      return {
        ...state,
        chargerStatusReferenceData: action.chargerStatusReferenceData,
      };
    case referenceDataTypes.FETCH_CHARGER_TYPE_REFERENCE_DATA:
      return {
        ...state,
        chargerTypeReferenceData: action.chargerTypeReferenceData,
      };
    case referenceDataTypes.FETCH_CUSTOMER_REFERENCE_DATA:
      return {
        ...state,
        customerReferenceData: action.customerReferenceData,
      };
    case referenceDataTypes.FETCH_REGION_REFERENCE_DATA:
      return {
        ...state,
        regionReferenceData: action.regionReferenceData,
      };

    case referenceDataTypes.FETCH_SOFTWARE_VERSION_REFERENCE_DATA:
      return {
        ...state,
        softwareVersionReferenceData: action.softwareVersionReferenceData,
      };
    case referenceDataTypes.FETCH_ALARM_SEVERITY_REFERENCE_DATA:
      return {
        ...state,
        alarmSeverityReferenceData: action.alarmSeverityReferenceData,
      };
    case referenceDataTypes.FETCH_CHARGER_MODEL_REFERENCE_DATA:
      return {
        ...state,
        chargerModelReferenceData: action.chargerModelReferenceData,
      };
    case referenceDataTypes.FETCH_CHARGER_STATION_REFERENCE_DATA:
      return {
        ...state,
        chargingStationReferenceData: action.chargingStationReferenceData,
      };
    case referenceDataTypes.FETCH_CHARGER_LOCATION_REFERENCE_DATA:
      return {
        ...state,
        chargerLocationReferenceData: action.chargerLocationReferenceData,
      };
    case referenceDataTypes.FETCH_HISTORY_TYPE_REFERENCE_DATA:
      return {
        ...state,
        chargerHistoryTypeReferenceData: action.chargerHistoryTypeReferenceData,
      };
    case referenceDataTypes.FETCH_HISTORY_INTERVAL_REFERENCE_DATA:
      return {
        ...state,
        chargerHistoryIntervalReferenceData: action.chargerHistoryIntervalReferenceData,
      };
    case referenceDataTypes.FETCH_ALARM_TYPE_REFERENCE_DATA:
      return {
        ...state,
        alarmTypeReferenceData: action.alarmTypeReferenceData,
      };
    case referenceDataTypes.FETCH_CHARGER_VISIBLE_ID_REFERENCE_DATA:
      return {
        ...state,
        chargerVisibleIdReferenceData: action.chargerVisibleIdReferenceData,
      };
    case referenceDataTypes.FETCH_USER_ROLE_TYPE_REFERENCE_DATA:
      return {
        ...state,
        userRoleTypeReferenceData: action.userRoleTypeReferenceData,
      };
    case referenceDataTypes.FETCH_PARAMETERS_REFERENCE_DATA:
      return {
        ...state,
        parametersReferenceData: action.parametersReferenceData,
      }
    case referenceDataTypes.FETCH_ORG_REFERENCE_DATA:
      return {
        ...state,
        orgReferenceData: action.orgReferenceData,
      };
    case referenceDataTypes.FETCH_ORG_TYPE_REFERENCE_DATA:
      return {
        ...state,
        orgTypeReferenceData: action.orgTypeReferenceData
      };
    case referenceDataTypes.FETCH_USERS_REFERENCE_DATA:
      return {
        ...state,
        userReferenceData: action.userReferenceData,
      };
    case referenceDataTypes.FETCH_CHARGER_VISIBILITY_REFERENCE_DATA:
      return {
        ...state,
        chargerVisibilityReferenceData: action.chargerVisibilityReferenceData,
      };
    case referenceDataTypes.FETCH_ROLE_PERMISSION_DATA:
      return {
        ...state,
        rolePermissionReferenceData: action.rolePermissionReferenceData
      };
    case referenceDataTypes.FETCH_EXCEPTION_LOGS_SEVERITY_REFERENCE_DATA:
      return {
        ...state,
        exceptionLogsSeverityReferenceData: action.exceptionLogsSeverityReferenceData
      };
    case referenceDataTypes.FETCH_ALLOWED_COMMANDS_FOR_ROLE_REFERENCE_DATA:
      return {
        ...state,
        allowedCommandsForRoleReferenceData: action.allowedCommandsForRoleReferenceData
      };
    case referenceDataTypes.FETCH_CHARGER_PRODUCT_TYPE_REFERENCE_DATA:
      return {
        ...state,
        chargerProductTypeReferenceData: action.chargerProductTypeReferenceData,
      };
    case referenceDataTypes.FETCH_RULE_ALARM_TYPES:
      return {
        ...state,
        alarmNameReferenceData: action.alarmNameReferenceData,
      };
    case referenceDataTypes.FETCH_RULE_ALARM_TYPES_EXECUTION_PLAN:
      return {
        ...state,
        alarmNameReferenceDataExecutionPlan: action.alarmNameReferenceDataExecutionPlan
      }
    case referenceDataTypes.FETCH_RULE_TYPE_OF_NOTIFICATION:
      return{
        ...state,
        typeOfNotificationReferenceData : action.typeOfNotificationReferenceData
      }
    case referenceDataTypes.FETCH_TRIGGER_POINT_PARAMETER_CONDITION_REFERENCE_DATA:
      return{
        ...state,
        ruleEngineTriggerPointParameterReferenceData : action.ruleEngineTriggerPointParameterReferenceData
      }
    case referenceDataTypes.FETCH_RULE_ID_CONFIGURE_NOTIFICATION_HISTORY:
      return{
        ...state,
        ruleIdConfigurNotificationHistoryReferenceData: action.ruleIdConfigurNotificationHistoryReferenceData
      }
      case referenceDataTypes.FETCH_RULE_ENGINE_TRIGGER_CATEGORY_REFERENCE_DATA:
        return {
          ...state,
          ruleEngineTriggerCategoryReferenceData: action.ruleEngineTriggerCategoryReferenceData,
        };
      case referenceDataTypes.FETCH_RULE_ENGINE_TRIGGER_LEVEL_REFERENCE_DATA:
          return {
            ...state,
            ruleEngineTriggerLevelReferenceData: action.ruleEngineTriggerLevelReferenceData,
          };
      case referenceDataTypes.FETCH_RULE_ENGINE_ACTION_CONTROL_REFERENCE_DATA:
      return {
        ...state,
        ruleEngineActionControlReferenceData: action.ruleEngineActionControlReferenceData,
      };
      case referenceDataTypes.FETCH_RULE_ENGINE_SCHEDULE_REFERENCE_DATA:
        return {
          ...state,
          ruleEngineScheduleReferenceData: action.ruleEngineScheduleReferenceData,
        };
        case referenceDataTypes.FETCH_RULE_ENGINE_STATUS_REFERENCE_DATA:
          return {
            ...state,
            ruleEngineStatusReferenceData: action.ruleEngineStatusReferenceData,
          };
      case referenceDataTypes.FETCH_RULE_ENGINE_PARAM_CONDITION:
        return{
          ...state,
          ruleEngineParamConditionReferenceData: action.ruleEngineParamConditionReferenceData,
        }
      case referenceDataTypes.FETCH_RULE_ENGINE_PARAM_OPERATOR:
        return{
          ...state,
          ruleEngineParamOperatorReferenceData: action.ruleEngineParamOperatorReferenceData
        }
      case referenceDataTypes.FETCH_RULE_ENGINE_ACTION_CONTROL_SEND_NOTIFICATION_RECIPIENT:
        return{
          ...state,
          ruleEngineActionControlSendNotificationReferenceData: action.ruleEngineActionControlSendNotificationReferenceData
        }
      case referenceDataTypes.FETCH_RULE_ENGINE_RAISE_A_TICKET_CATEGORY:
        return{
          ...state,
          ruleEngineRaiseATicketCategoryReferenceData: action.ruleEngineRaiseATicketCategoryReferenceData
        }
      case referenceDataTypes.FETCH_RULE_ENGINE_RAISE_A_TICKET_URGENCY:
        return{
          ...state,
          ruleEngineRaiseATicketUrgencyReferenceData: action.ruleEngineRaiseATicketUrgencyReferenceData
        }
      case referenceDataTypes.FETCH_RULE_ENGINE_RAISE_A_TICKET_CALL_TYPE:
        return{
          ...state,
          ruleEngineRaiseATicketCallTypeReferenceData: action.ruleEngineRaiseATicketCallTypeReferenceData
        }
      case referenceDataTypes.FETCH_RULE_ENGINE_RULE_NAME_REFERENCE_DATA:
          return{
            ...state,
            ruleEngineRuleNameReferenceData: action.ruleEngineRuleNameReferenceData
          }
      case referenceDataTypes.FETCH_RULE_ENGINE_SET_PARAMETER_PARAM:
        return{
          ...state,
          ruleEngineSetParameterParam: action.ruleEngineSetParameterParam,
        }
      case referenceDataTypes.FETCH_RULE_ENGINE_REMOTE_COMMANDS:
        return{
          ...state,
          ruleEngineRemoteCommands: action.ruleEngineRemoteCommands,
        }
      case referenceDataTypes.FETCH_ALARM_RULE_SEVERITY:
        return{
          ...state,
          alarmRuleSeverityReferenceData: action.alarmRuleSeverityReferenceData,
        }
        case referenceDataTypes.FETCH_CHARGER_ACTIVE_CERTIFICATE_REFERENCE_DATA:
          return {
            ...state,
            chargerActiveCertificateReferenceData: action.chargerActiveCertificateReferenceData,
          };
          case referenceDataTypes.FETCH_RESET_COUNTER_REMOTE_COMMAND_REF_DATA:
            return {
              ...state,
              resetCounterRemoteCommandReferenceData: action.resetCounterRemoteCommandReferenceData,
            };
    default:
      return state;
  }
};

export default referenceDataReducer;
