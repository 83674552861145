/* eslint-disable @typescript-eslint/ban-types */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ExecutionPlan, {  ExecutionPlanTrigger } from './ExecutionPlan';
import { CustomButtonGroup, CustomInput, SelectDropDown, useAppSelector } from '../../../globalUtils/globalExports';
import { cloneDeep, isEqual } from 'lodash';
import { getReferenceDataReducer } from '../../../rmsReduxStore/reduxExports';
import { parseDropDownValues } from '../../chargers/ChargerPanel/ChargerPanelSettingsDashboard';
import './TriggerPointSidePopover.css'
import { checkValidDecimal, checkValidInteger } from './itThenElseSidePopOver';

interface TriggerPointSidePopoverProps {
    handleClose: Function;
    executionPlan: ExecutionPlan;
    handleUpdateExecutionPlan: Function;
    parameterOrAlarm: string;
}

const TriggerPointSidePopover = (function TriggerPointSidePopover({handleClose,
    executionPlan,
    handleUpdateExecutionPlan,
    parameterOrAlarm,
} : TriggerPointSidePopoverProps){

    const { ruleEngineTriggerPointParameterReferenceData, ruleEngineParamConditionReferenceData, alarmNameReferenceData} = useAppSelector(getReferenceDataReducer);

    const checkMinMax =(val) =>{
        if (!val || typeof val !== 'string') {
            return false;
        }
        try {
            const options = parseDropDownValues(val);
            return (options.length >0 && (options[0].id === 'min' || options[1].id === 'max')) ? true : false;
        } catch (error) {
            return false;
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const checkValidationsAndShowError = (subParameter, value, place): string | null => {
        const parsedValues = JSON.parse(subParameter?.possibleValue)
        // if (subParameter?.dataType === 'String') {
        //   return parsedValues && value?.length >= parsedValues['max'] ? `Only ${parsedValues['max']} characters are allowed` : null

        if (subParameter?.dataType === 'Integer') {
            return value && !checkValidInteger(value) ? 'Enter a valid Integer value' :  (subParameter?.parameterConditionName === 'Between' || subParameter?.parameterConditionName === 'Not between') ? ((subParameter && subParameter?.parameterValue.length > 1  && parseInt(subParameter?.parameterValue[0]) >  parseInt(subParameter?.parameterValue[1])) ? 'The second value must be greater than the first value' : null) : parsedValues && !(parseInt(value) >= parsedValues['min'] && parseInt(value) <= parsedValues['max']) ? `Please enter a value between ${parsedValues['min']} and ${parsedValues['max']}.`
             : null
          } else if (subParameter?.dataType === 'Double') {
            return value && !checkValidDecimal(value) ? 'Enter a valid Double value' : (subParameter?.parameterConditionName === 'Between'|| subParameter?.parameterConditionName === 'Not between')? ((subParameter && subParameter?.parameterValue.length > 1 &&  parseFloat(subParameter?.parameterValue[0]) >  parseFloat(subParameter?.parameterValue[1]) ) ? 'The second value must be greater than the first value' : null) : parsedValues && !(parseFloat(value) >= parsedValues['min'] && parseFloat(value) <= parsedValues['max']) ? `Please enter a value between ${parsedValues['min']} and ${parsedValues['max']}.` : null
          } 
          return null
    }
    
    const [initialPopOverData,setInitialPopOverData] = useState<ExecutionPlanTrigger>({
        type: null,
        alarmTypeId: null,
        parameterId: null,
        name: null,
        parameterCondition: null,
        parameterConditionName: null,
        parameterValue: [],
        dataType: null,
        fieldType : null ,
        unit: null,
        possibleValue: null,
        errorMessage: null,
        errorMessageSecondary: null,
        parameterType: null,
        scheduleType: null,
        dateTime: null,
        recurrenceDays: null,
        recurrenceRange: null,
        recurrenceCount: null,
        endDate: null
    })

    const [popOverData,setPopOverData] = useState<ExecutionPlanTrigger>({
        type: null,
        alarmTypeId: null,
        parameterId: null,
        name: null,
        parameterCondition: null,
        parameterConditionName: null,
        parameterValue: [], 
        dataType: null,
        fieldType : null ,
        unit: null,
        possibleValue: null,
        errorMessage: null,
        errorMessageSecondary: null,
        parameterType: null,
        scheduleType: null,
        dateTime: null,
        recurrenceDays: null,
        recurrenceRange: null,
        recurrenceCount: null,
        endDate: null
    })
    const disableButton = useCallback(() => {
        if(isEqual(initialPopOverData,popOverData)){
            return true;
        }else {
            let result = true;
            // console.log('valid entering',popOverData,popOverData.parameterValue[1].length)
            if(parameterOrAlarm === 'Parameter'){
                if((popOverData.parameterConditionName === 'Between' || popOverData.parameterConditionName === 'Not between')){
                    result = result && (popOverData.parameterId !== null &&  popOverData.parameterId.length > 0  && popOverData.parameterValue && popOverData.parameterValue?.length > 1   && popOverData.parameterValue[0].length > 0 && (popOverData?.errorMessage === null) && (popOverData?.errorMessageSecondary ===null));
                    return !result;
                }
                else if((popOverData.dataType === 'String' || popOverData.dataType === 'Boolean' || (popOverData?.possibleValue !==null && !checkMinMax(popOverData?.possibleValue)))){
                    result = result && (popOverData.parameterId!== null && popOverData.parameterId.length > 0 && popOverData.parameterCondition!== null  && popOverData.parameterValue.length > 0 && popOverData.parameterValue[0].length > 0)
                    return !result
                }
                else if(popOverData.parameterId && popOverData.parameterCondition && popOverData.parameterId.length > 0 && popOverData.parameterCondition.length > 0 && popOverData.parameterValue.length>0){
                    result = result && popOverData.parameterId.length > 0 && popOverData.parameterCondition.length > 0 && popOverData.parameterValue.length > 0;
                    return !result
                }
                return true;
            } else if(parameterOrAlarm === 'Alarm'){
                return popOverData.alarmTypeId === null;
            }
        }   
    }, [popOverData,parameterOrAlarm]); 

    // const renderDropDownOptions = (val) => {
    //     if (!val || typeof val !== 'string') {
    //         return [];
    //     }
    //     try {
    //         const options = parseDropDownValues(val);
    //         return options;
    //     } catch (error) {
    //         return [];
    //     }
    // }

    const btnsList = useMemo(() => {
        return [
          {
            buttonText: 'Save',
            buttonId: 'add',
            btnClassName:  disableButton() ? 'primary__btn disabled' : 'primary__btn',
            handleClick: (): void => {
                  const currentData = cloneDeep(popOverData)
                  const data = cloneDeep(executionPlan);
                  data.trigger = currentData;
                //   const firstCondition: ExecutionPlanCondition = {
                //       index: 0,
                //       type: parameterOrAlarm ?? '',
                //       alarmTypeId: currentData.alarmTypeId ?? '',
                //       parameterId: currentData.parameterId ?? '',
                //       parameterName: currentData.name ?? '',
                //       parameterCondition: currentData.parameterCondition ?? '',
                //       parameterConditionName: currentData.parameterConditionName ?? '',
                //       parameterValue: currentData.parameterValue ?? [],
                //       operator: 'AND',
                //       dataType: currentData.dataType ?? '',
                //       fieldType: currentData.fieldType ?? '',
                //       unit: currentData.unit ?? '',
                //       possibleValue: currentData.possibleValue ?? '',
                //       errorMessage: currentData.errorMessage ?? '',
                //       errorMessageSecondary: currentData.errorMessageSecondary ?? '',
                //       parameterType: currentData.parameterType?? null
                //   }
                //   data.condition[0] = firstCondition;
               
                  handleUpdateExecutionPlan(data);
                  handleClose();
            },
            isDisabled: disableButton(),
            buttonVariant: 'filled',
          },
          {
            buttonText: 'Cancel',
            buttonId: 'cancel',
            btnClassName: 'secondary__btn',
            handleClick: (): void => {
                handleClose()
            },
            isDisabled: false,
            buttonVariant: 'outlined',
          },
        ];
      }, [popOverData]); 

      useEffect(()=>{
        if(executionPlan.trigger && executionPlan.trigger.type === parameterOrAlarm){
            setInitialPopOverData(executionPlan.trigger);
            setPopOverData(executionPlan.trigger);
        }
      },[])
    
    return (
        <>
         <div className='trigger__point__side__popover__wrapper'>
            <div>
                <div className='rule__engine__raise__a__ticket__side__popover__row__label'>
                    {parameterOrAlarm === 'Parameter' ? 'Parameter*' : 'Alarm*'}
                </div>
                <div>
                    {parameterOrAlarm === 'Parameter' ? 
                    <SelectDropDown 
                    dropDownClassName={'trigger__parameter__select'}
                    selectDropDownId={`trigger__point__${parameterOrAlarm}__dropdown`} 
                    handleSelectOptionChange={(val)=>{
                        const data = cloneDeep(popOverData);
                        data.type = 'Parameter';
                        data.parameterId = val?.id;
                        data.dataType = val?.dataType;
                        data.fieldType = val?.fieldType;
                        data.unit = val?.unit;
                        data.possibleValue = val?.possibleValue;
                        data.parameterValue = [];
                        data.errorMessage = '';
                        data.parameterCondition = null;
                        data.parameterConditionName = null;
                        data.name = val?.label;
                        data.parameterType = val?.parameterType;
                        setPopOverData(data);
                    }} 
                    renderToBody={true}
                    selectOptions={ruleEngineTriggerPointParameterReferenceData ?? []}
                    selectDropDownName={`${parameterOrAlarm}*`}
                    selectValue={{ id: popOverData.name, label: popOverData.name }}
                    /> : 
                    <SelectDropDown 
                    selectDropDownId={`trigger__point__${parameterOrAlarm}__dropdown`} 
                    handleSelectOptionChange={(val)=> {
                        const data = cloneDeep(popOverData);
                        data.type = 'Alarm';
                        data.alarmTypeId = val?.id;
                        data.parameterCondition = 'is'
                        data.parameterConditionName = 'is'
                        data.dataType = null;
                        data.fieldType = null;
                        data.unit = null
                        data.possibleValue = null
                        data.parameterValue =['Active'];
                        data.errorMessage = '';
                        data.name = val?.label;
                        setPopOverData(data);
                    }} 
                    selectOptions={alarmNameReferenceData ?? []}
                    selectDropDownName={`${parameterOrAlarm}*`}
                    selectValue={{ id: popOverData.name, label: popOverData.name}}
                    />
                    }
                </div>
            </div>
            <div className='trigger__point__operator__value__wrapper'>
                <div style={{width: '50%'}}>
                <div className='rule__engine__raise__a__ticket__side__popover__row__label'>Operator*</div>
                <div >
                    { parameterOrAlarm === 'Parameter' ? 
                    <SelectDropDown 
                    selectDropDownId={`trigger__point__${parameterOrAlarm}__dropdown`} 
                    handleSelectOptionChange={(val)=>{
                        const data = cloneDeep(popOverData);
                        data.parameterCondition = val?.id;
                        data.parameterConditionName = val?.label;
                        data.parameterValue = [];
                        setPopOverData(data);
                    }} 
                    selectOptions={(popOverData.dataType === 'String' || popOverData.dataType === 'Boolean' || (popOverData?.possibleValue !==null && !checkMinMax(popOverData?.possibleValue)) ) ? ruleEngineParamConditionReferenceData?.slice(0,2) ?? [] : ruleEngineParamConditionReferenceData?.slice(2) ?? []} // if possible value does not have min max then is/is not
                    selectDropDownName={`${parameterOrAlarm}*`}
                    selectValue={{id: popOverData.parameterConditionName,label: popOverData.parameterConditionName}}
                    isDisabled={popOverData.parameterId ? false : true}
                    renderToBody={true}
                    /> :
                    <CustomInput 
                    inputValue={popOverData.parameterCondition ?? ''} 
                    inputFieldId={`trigger__point__${parameterOrAlarm}__operator__input`} 
                    isRequired={true}
                    isDisabled={true}
                    />
                    }
                </div>
                </div>
                <div style={{width: '50%'}}>
                    <div className='rule__engine__raise__a__ticket__side__popover__row__label'>
                     Value*
                    </div>
                    {
                    parameterOrAlarm === 'Parameter' ? 
                    <div className='trigger__point__side__popover__value__label'>
                    {popOverData?.possibleValue && popOverData?.possibleValue !== null && !checkMinMax(popOverData?.possibleValue) ? (  
                        <div style={{width: '270px'}}>
                            <SelectDropDown 
                                selectDropDownId={`trigger_point__dropdown__${parameterOrAlarm}`} 
                                handleSelectOptionChange={(val)=>{
                                    const data = cloneDeep(popOverData);
                                    data.parameterValue[0] = (val?.label)
                                    setPopOverData(data);
                                    
                                } } 
                                selectOptions={parseDropDownValues(popOverData?.possibleValue) ?? []}
                                isMultiSelect={false} 
                                selectValue={{id: popOverData.parameterValue[0], label : popOverData.parameterValue[0]}}
                        />
                        </div> 
                    ) : 
                        (
                        <>
                        <CustomInput 
                            inputValue={popOverData.parameterCondition &&  popOverData.parameterCondition.length > 0 ? popOverData.parameterValue[0] : ''} 
                            inputFieldId={`trigger__point__value__input_${parameterOrAlarm}`} 
                            isRequired={true}   
                            isDisabled={popOverData.parameterCondition && popOverData.parameterCondition.length>0 ? false : true}
                            handleInputChange={(e) => {
                            const data = cloneDeep(popOverData);
                            if(e.target.value !== '' && e.target.value.length>0){
                                data.parameterValue[0] = e.target.value;
                                data.errorMessage = null;
                                data.errorMessageSecondary = null;
                                const error =  checkValidationsAndShowError(data,e.target.value,'1')
                                data.errorMessage = error!==null ? error : null;
                                setPopOverData(data); 
                            }else{
                                data.parameterValue[0]='';
                                data.errorMessage = null;
                                setPopOverData(data); 
                                } 
                            }} 
                            errorMessage={popOverData.errorMessage}
                        />
                        {popOverData?.unit && popOverData?.unit !== 'NA' && (
                            <div className='charger__status__toggle__wrap__content-row-value-wrap-unit'>
                            {popOverData.unit.slice(0,3)}
                            </div>
                        )}
                        
                        {popOverData && (popOverData.parameterConditionName === 'Between' || popOverData.parameterConditionName === 'Not between' ) && (
                            <>
                            <CustomInput 
                                inputValue={popOverData.parameterCondition && popOverData.parameterCondition.length >0 ?  popOverData.parameterValue[1] : ''} 
                                inputFieldId={'trigger__point__value__input__secondary'} 
                                isRequired={true} 
                                isDisabled={popOverData.parameterValue.length === 0}  
                                handleInputChange={(e) => {
                                const data = cloneDeep(popOverData);
                                if(e.target.value !== '' && e.target.value.length > 0){
                                    data.parameterValue[1] =(e.target.value);
                                    data.errorMessage = null;
                                    data.errorMessageSecondary = null;
                                    const error =  checkValidationsAndShowError(data,e.target.value,'2');
                                    data.errorMessageSecondary = error!== null ? error : null;
                                    setPopOverData(data);
                                }else {
                                    data.parameterValue.pop();
                                    data.errorMessageSecondary=null;
                                    setPopOverData(data);
                                }   
                                // handleErrorMessage(data[i]['parameterValue'])
                                }} 
                                errorMessage={popOverData.errorMessageSecondary}
                            />
                            {popOverData?.unit && popOverData?.unit !== 'NA' && (
                                <div className='charger__status__toggle__wrap__content-row-value-wrap-unit'>
                                {popOverData.unit.slice(0,3)}
                                </div>
                            )}
                            </>
                        )}
                        </>
                    )}
                    </div>
                    :
                    <div className='trigger__point__side__popover__value__label'>
                    {/* {
                    popOverData?.fieldType === 'DropDownMultiple' ? (
                        <div style={{width: '180px'}}>
                            <SelectDropDown 
                                selectDropDownId={`trigger__point__${parameterOrAlarm}__dropdown__value`} 
                                handleSelectOptionChange={(val)=>{
                                    const data = cloneDeep(popOverData);
                                    data.parameterValue.push(val?.label);
                                    setPopOverData(data);
                                } } 
                                selectOptions={renderDropDownOptions(popOverData?.possibleValue) ?? []} 
                                selectValue={{id : popOverData.parameterValue, label : popOverData.parameterValue}}
                                isDisabled={popOverData?.parameterCondition &&  popOverData?.parameterCondition.length > 0 ? false : true}
                                isMultiSelect={true}
                        />
                        </div>
                    ) : popOverData?.fieldType === 'DropDownSingle' ?
                        <div style={{width: '180px'}}>
                            <SelectDropDown 
                                selectDropDownId={`trigger__point__${parameterOrAlarm}__single__select__dropdown`}
                                selectOptions={renderDropDownOptions(popOverData?.possibleValue) ?? []} 
                                isDisabled={popOverData?.parameterCondition &&  popOverData?.parameterCondition.length > 0 ? false : true}
                                handleSelectOptionChange={(val)=>{
                                    const data = cloneDeep(popOverData);
                                    data.parameterValue[0] = val?.label
                                    setPopOverData(data);
                                }} 
                                isMultiSelect={false}
                                selectValue={{ id: popOverData.parameterValue[0], label : popOverData.parameterValue[0]}}
                        />
                        </div>
                    :  */}
                        <>
                        <CustomInput 
                            inputValue={popOverData.parameterValue[0] ?? ''} 
                            inputFieldId={`trigger__point__${parameterOrAlarm}__input__value`} 
                            isRequired={true}   
                            isDisabled={true}
                            // handleInputChange={(e) => {
                            // const data = cloneDeep(popOverData);
                            // console.log(data,'enter');
                            // data.parameterValue[0]= (e.target.value);
                            // console.log(e.target.value,popOverData,'setParameterValue')
                            // const error = checkValidationsAndShowError(popOverData,e.target.value);
                            // data.errorMessage = error!==null ? error : '';
                            // setPopOverData(data);
                            // }} 
                            // errorMessage={popOverData.errorMessage}
                        />
                        {/* {popOverData?.fieldType === 'Input' && popOverData?.unit && popOverData?.unit !== 'NA' && (
                            <div className='charger__status__toggle__wrap__content-row-value-wrap-unit'>
                            {popOverData.unit.slice(0,3)}
                            </div>
                        )} */}
                        
                        </>
                    {/* } */}
                    </div>
                    }
                    {/* <CustomInput 
                        inputValue={''} 
                        inputFieldId={'trigger__point'} 
                        isRequired={true} 
                        isDisabled={false}  
                        handleInputChange={(e) => {
                            handleValueChange(e.target.value,'0');
                        }} 
                        /> */}
                
                </div>
            </div>
         </div>
         <div className='rule__engine__if__then__else__side__popover__footer'>
            <CustomButtonGroup
                buttonsList={btnsList}
                buttonGroupClassName='button__group__footer' />
            </div>
        </>
    )
})

export default TriggerPointSidePopover;


